body .navbar {
  --navbar-height: 7rem !important;
}

.navbar ul li a,
.navbar ul li a:after,
.navbar ul li a:before {
  transition: all 0.5s;
}

.navbar.stroke ul li a,
.navbar.fill ul li a {
  position: relative;
}

.navbar.stroke ul li a:after,
.navbar.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: -10px;
  width: 0%;
  content: ".";
  color: transparent;
  background: black;
  height: 2px;
}

.navbar.stroke ul li a:hover:after {
  width: 100%;
}
